// you also have to add config values to public/config.js for production builds
const devConfig = {
  azureMapsKey: process.env.REACT_APP_AZURE_MAPS_KEY || "",
  backendClientId: process.env.REACT_APP_BACKEND_CLIENT_ID || "",
  disablePersistQueryCache:
    process.env.REACT_APP_DISABLE_PERSIST_QUERY_CACHE || "",
  msalAuthority: process.env.REACT_APP_MSAL_AUTHORITY || "",
  msalClientId: process.env.REACT_APP_MSAL_CLIENT_ID || "",
  msalRedirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI || "",
  sentryDsn: process.env.REACT_APP_SENTRY_DSN || "",
  gitSha: process.env.REACT_APP_GIT_SHA || "",
  sentrySampleRate: process.env.REACT_APP_SENTRY_SAMPLE_RATE || "",
  version: process.env.REACT_APP_VERSION || "development",
  commuteExpensesFeatureEnabled: "1,3,4,5",
  systemEnvironment: process.env.REACT_APP_ENVIRONMENT || "development",
  enabledFeatures: process.env.REACT_APP_ENABLED_FEATURES || "",
  watchPositionFeatureEnabled: "1,3,4,5",
};

declare global {
  interface Window {
    config: typeof devConfig;
  }
}

export function config() {
  if (["development", "test"].includes(process.env.NODE_ENV)) {
    return devConfig;
  }
  // config is set in public/config.js and injected via caddy
  return window.config;
}
