/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CommuteType } from './CommuteType';
import type { Resource } from './Resource';
import type { TrackingTaskType } from './TrackingTaskType';
export type TimeTrackingAppendEventBody = {
    action: TimeTrackingAppendEventBody.action;
    start_time: string;
    stop_time: string;
    pause_duration_seconds: number;
    work_package_id?: string;
    resource: Resource;
    tracking_task_type: TrackingTaskType;
    auto_created?: boolean;
    arrival_commute_type: CommuteType;
    departure_commute_type: CommuteType;
    arrival_commute_time_seconds?: number;
    departure_commute_time_seconds?: number;
};
export namespace TimeTrackingAppendEventBody {
    export enum action {
        APPEND = 'append',
    }
}

