/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BusinessLine } from '../models/BusinessLine';
import type { BusinessLineHoursEvaluations } from '../models/BusinessLineHoursEvaluations';
import type { EmployeeHoursEvaluations } from '../models/EmployeeHoursEvaluations';
import type { HoursEvaluationRow } from '../models/HoursEvaluationRow';
import type { WageTypeHoursEvaluations } from '../models/WageTypeHoursEvaluations';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ControllingHoursEvaluationsService {
    /**
     * returns hours by month
     * @param tenantId tenant_id
     * @param year year
     * @param superiorId superior_id
     * @returns WageTypeHoursEvaluations successful
     * @throws ApiError
     */
    public static getWageTypeHours(
        tenantId: string,
        year: number,
        superiorId?: string,
    ): CancelablePromise<WageTypeHoursEvaluations> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tenants/{tenant_id}/controlling/hours_evaluations/wage_type',
            path: {
                'tenant_id': tenantId,
            },
            query: {
                'year': year,
                'superior_id': superiorId,
            },
        });
    }
    /**
     * returns hours by employee by month for a business line
     * @param tenantId tenant_id
     * @param year year
     * @param row row
     * @param businessLine business_line
     * @param superiorId superior_id
     * @returns EmployeeHoursEvaluations successful
     * @throws ApiError
     */
    public static getEmployeeHours(
        tenantId: string,
        year: number,
        row: HoursEvaluationRow,
        businessLine?: (BusinessLine | 'unknown'),
        superiorId?: string,
    ): CancelablePromise<EmployeeHoursEvaluations> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tenants/{tenant_id}/controlling/hours_evaluations/employee',
            path: {
                'tenant_id': tenantId,
            },
            query: {
                'year': year,
                'row': row,
                'business_line': businessLine,
                'superior_id': superiorId,
            },
        });
    }
    /**
     * returns hours by business lines by month
     * @param tenantId tenant_id
     * @param year year
     * @param row row
     * @param staffNumber staff_number
     * @param superiorId superior_id
     * @returns BusinessLineHoursEvaluations successful
     * @throws ApiError
     */
    public static getBusinessLineHours(
        tenantId: string,
        year: number,
        row: HoursEvaluationRow,
        staffNumber?: string,
        superiorId?: string,
    ): CancelablePromise<BusinessLineHoursEvaluations> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tenants/{tenant_id}/controlling/hours_evaluations/business_line',
            path: {
                'tenant_id': tenantId,
            },
            query: {
                'year': year,
                'row': row,
                'staff_number': staffNumber,
                'superior_id': superiorId,
            },
        });
    }
}
