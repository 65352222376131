import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";

import { useDebounce } from "~components/shared/useDebounce.ts";
import { useTranslation } from "~contexts/I18nContext/I18nContext.tsx";

import type { ChangeEvent } from "react";

export function SearchField({
  value,
  onChange,
  minWidth,
  maxWidth,
  placeholder,
}: {
  value: string;
  onChange: (value: string) => void;
  minWidth?: string;
  maxWidth?: string;
  placeholder?: string;
}) {
  const t = useTranslation();
  const [searchTerm, setSearchTerm] = useState(value);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const handleChange = ({
    target: { value: newValue },
  }: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(newValue);
  };

  const handleReset = () => {
    setSearchTerm("");
  };

  useEffect(() => {
    onChange(debouncedSearchTerm);
  }, [debouncedSearchTerm, onChange]);

  return (
    <TextField
      value={searchTerm}
      onChange={handleChange}
      label={t.searchFieldLabel}
      size="small"
      sx={{ maxWidth, minWidth }}
      placeholder={placeholder}
      InputProps={{
        endAdornment: (
          <>
            {value && value !== "" && (
              <InputAdornment position="end">
                <IconButton
                  sx={{ width: "28px", height: "28px" }}
                  onClick={handleReset}
                >
                  <ClearIcon sx={{ width: "20px", height: "20px" }} />
                </IconButton>
              </InputAdornment>
            )}
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          </>
        ),
      }}
    />
  );
}
