/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Absence } from '../models/Absence';
import type { Absences } from '../models/Absences';
import type { EditAbsence } from '../models/EditAbsence';
import type { NewAbsence } from '../models/NewAbsence';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AbsencesService {
    /**
     * list absences
     * @param tenantId tenant_id
     * @param startDate start_date
     * @param endDate end_date
     * @returns Absences successful
     * @throws ApiError
     */
    public static getAbsences(
        tenantId: string,
        startDate?: string,
        endDate?: string,
    ): CancelablePromise<Absences> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/tenants/{tenant_id}/absences',
            path: {
                'tenant_id': tenantId,
            },
            query: {
                'start_date': startDate,
                'end_date': endDate,
            },
        });
    }
    /**
     * create absence
     * @param tenantId tenant_id
     * @param requestBody
     * @returns Absence absence created
     * @throws ApiError
     */
    public static createAbsence(
        tenantId: string,
        requestBody?: NewAbsence,
    ): CancelablePromise<Absence> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/tenants/{tenant_id}/absences',
            path: {
                'tenant_id': tenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * delete absence
     * @param tenantId tenant_id
     * @param id id
     * @returns void
     * @throws ApiError
     */
    public static deleteAbsence(
        tenantId: string,
        id: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/tenants/{tenant_id}/absences/{id}',
            path: {
                'tenant_id': tenantId,
                'id': id,
            },
        });
    }
    /**
     * update absence
     * @param tenantId tenant_id
     * @param id id
     * @param requestBody
     * @returns Absence absence updated
     * @throws ApiError
     */
    public static updateAbsence(
        tenantId: string,
        id: string,
        requestBody?: EditAbsence,
    ): CancelablePromise<Absence> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/tenants/{tenant_id}/absences/{id}',
            path: {
                'tenant_id': tenantId,
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `absence could not be updated`,
            },
        });
    }
}
