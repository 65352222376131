import { useQuery } from "@tanstack/react-query";
import { formatISO } from "date-fns/formatISO";

import { tenantKeys } from "~api/tenants.ts";
import { useCurrentTenant } from "~contexts/CurrentTenantContext/CurrentTenantContext.tsx";
import { TimeEntriesService } from "~generated";
import { isValidDate } from "~lib/dateHelpers.ts";

import type { DateRange as MuiDateRange } from "@mui/x-date-pickers-pro";
import type { DateRange } from "~lib/dateHelpers.ts";

type TimeEntriesFilters = {
  startDate?: string;
  endDate?: string;
};

type ExportPreviewFilters = {
  startDate?: string;
  stopDate?: string;
  onlyUnexported: boolean;
};

type OverlappingTimeEntriesFilters = {
  resourceId: string;
  timeRange: DateRange;
};

export const timeEntriesKeys = {
  base: (tenantId: string) =>
    [...tenantKeys.detail(tenantId), "timeEntries"] as const,
  list: (tenantId: string, filters?: TimeEntriesFilters) =>
    [...timeEntriesKeys.base(tenantId), "list", filters] as const,
  exportPreview: (tenantId: string, filters: ExportPreviewFilters) =>
    [...timeEntriesKeys.base(tenantId), "exportPreview", filters] as const,
  detail: (tenantId: string, id: string) =>
    [...timeEntriesKeys.base(tenantId), "detail", id] as const,
  history: (tenantId: string, id: string) =>
    [...timeEntriesKeys.detail(tenantId, id), "history"] as const,
  overlapping: (filters: OverlappingTimeEntriesFilters) =>
    ["timeEntries", "overlapping", filters] as const,
};

export function useTimeEntries(
  [startDate, endDate]: MuiDateRange<Date> | void = [null, null],
) {
  const currentTenant = useCurrentTenant();

  const startDateISO = startDate
    ? formatISO(startDate, { representation: "date" })
    : undefined;
  const endDateISO = endDate
    ? formatISO(endDate, { representation: "date" })
    : undefined;

  return useQuery({
    queryKey: timeEntriesKeys.list(currentTenant.id, {
      startDate: startDateISO,
      endDate: endDateISO,
    }),
    queryFn: async ({ signal }) => {
      const request = TimeEntriesService.getTimeEntries(
        currentTenant.id,
        startDateISO,
        endDateISO,
      );

      signal?.addEventListener("abort", () => {
        request.cancel();
      });

      return (await request).time_entries;
    },
  });
}

export function useTimeEntryHistoryEntries({
  timeEntryId,
}: {
  timeEntryId?: string;
}) {
  const currentTenant = useCurrentTenant();

  return useQuery({
    queryKey: timeEntriesKeys.history(currentTenant.id, timeEntryId ?? ""),
    enabled: !!timeEntryId,
    queryFn: async () =>
      (
        await TimeEntriesService.getTimeEntryHistoryEntries(
          currentTenant.id,
          timeEntryId ?? "",
        )
      ).history_entries,
  });
}

export function useTimeEntriesExportPreview({
  tenantId,
  startDate,
  stopDate,
  onlyUnexported,
  queryEnabled,
}: {
  tenantId: string;
  startDate?: Date | null;
  stopDate?: Date | null;
  onlyUnexported: boolean;
  queryEnabled: boolean;
}) {
  const startDateISO = formatISO(startDate || new Date(), {
    representation: "date",
  });
  const endDateISO = formatISO(stopDate || new Date(), {
    representation: "date",
  });

  return useQuery({
    queryKey: timeEntriesKeys.exportPreview(tenantId, {
      startDate: startDateISO,
      stopDate: startDateISO,
      onlyUnexported,
    }),
    queryFn: async () => {
      const request = TimeEntriesService.getTimeEntriesExportPreview(
        tenantId,
        startDateISO,
        endDateISO,
        onlyUnexported,
      );

      return (await request).time_entries;
    },
    enabled: queryEnabled,
  });
}

export function useOverlappingTimeEntries({
  resourceId,
  timeRange,
}: {
  resourceId?: string;
  timeRange: DateRange | null;
}) {
  let timeRangeKey: DateRange;
  if (timeRange && isValidDate(timeRange.start) && isValidDate(timeRange.end)) {
    timeRangeKey = timeRange;
  } else {
    timeRangeKey = {
      start: new Date(0),
      end: new Date(0),
    };
  }

  const queryKey = timeEntriesKeys.overlapping({
    resourceId: resourceId || "undefined",
    timeRange: timeRangeKey,
  });

  return useQuery({
    queryKey,
    queryFn: async () => {
      if (!resourceId || !timeRange) {
        return [];
      }
      const request = TimeEntriesService.getOverlappingTimeEntries(
        resourceId,
        formatISO(timeRange.start),
        formatISO(timeRange.end),
      );
      return (await request).overlapping_time_entries;
    },
  });
}
