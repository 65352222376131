import { useQuery } from "@tanstack/react-query";
import { createContext, useContext } from "react";

import { GenericError } from "~components/shared/ErrorPages/GenericError.tsx";
import { Spinner } from "~components/shared/Loading/Spinner/Spinner";
import { BusinessConfigService } from "~generated";

import type { AzureGroup } from "grewe-libs/src/lib/msgraph/groups/AzureGroup.ts";
import type { AzureTeam } from "grewe-libs/src/lib/msgraph/teams/AzureTeam.ts";
import type { ExportWageTypes, Tenant } from "grewe-libs/src/types/Tenant.ts";
import type { PropsWithChildren } from "react";

export type BusinessConfigTenant = BusinessConfig["tenants"][number];
export type BusinessConfigWageTypes = ExportWageTypes;
export type BusinessConfigWageType = keyof BusinessConfigWageTypes;

const businessConfigContext = createContext<BusinessConfig | null>(null);

function useBusinessConfigQuery() {
  return useQuery({
    queryKey: ["businessConfig"],
    queryFn: async () =>
      (await BusinessConfigService.getBusinessConfig()) as BusinessConfig,
  });
}

export function useBusinessConfig() {
  const context = useContext(businessConfigContext);
  if (!context) {
    throw new Error(
      "useBusinessConfig must be used within a BusinessConfigProvider",
    );
  }
  return context;
}

export function BusinessConfigProvider({ children }: PropsWithChildren) {
  const { data: businessConfig, isPending } = useBusinessConfigQuery();

  if (isPending) {
    return <Spinner />;
  }

  if (!businessConfig) {
    return <GenericError />;
  }

  return (
    <businessConfigContext.Provider value={businessConfig}>
      {children}
    </businessConfigContext.Provider>
  );
}

export function TestBusinessConfigProvider({
  children,
  businessConfig,
}: PropsWithChildren<{ businessConfig: BusinessConfig }>) {
  return (
    <businessConfigContext.Provider value={businessConfig}>
      {children}
    </businessConfigContext.Provider>
  );
}

export type BusinessConfig = {
  abilities: BusinessConfigAbility[];
  tenants: Tenant[];
  licences: AzureGroup[];
  teams: AzureTeam[];
  jobTitleRoleMapping: JobTitleRoleMapping;
  serviceCategories: readonly ServiceCategory[];
};

type BusinessConfigAbility = {
  id: string;
  displayName: string;
  description: string | null;
};

type JobTitleRoleMapping = {
  trainee: string[];
  managingDirector: string[];
  teamAssistance: string[];
  managingDirectorAssistance: string[];
  blueCollorWorker: string[];
  whiteCollorWorker: string[];
  foreman: string[];
  constructionSiteManager: string[];
  payrollClerk: string[];
  toolManager: string[];
};

type ServiceCategory = {
  key: string;
  cost_center: string;
  name: string;
};
