import { Outlet } from "react-router-dom";

import { PositionProvider } from "~contexts/PositionContext/PositionContext";
import { PositionErrorModalProvider } from "~contexts/PositionContext/PositionErrorModalContext";

export function TimeTrackingOutlet() {
  return (
    <PositionProvider>
      <PositionErrorModalProvider>
        <Outlet />
      </PositionErrorModalProvider>
    </PositionProvider>
  );
}
