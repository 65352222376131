import { captureException, withScope } from "@sentry/react";
import { createAsyncStoragePersister } from "@tanstack/query-async-storage-persister";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
  onlineManager,
} from "@tanstack/react-query";
import {
  PersistQueryClientProvider,
  removeOldestQuery,
} from "@tanstack/react-query-persist-client";
import { hoursToMilliseconds } from "date-fns/hoursToMilliseconds";
import { del, get, set } from "idb-keyval";
import { type PropsWithChildren, useMemo } from "react";

import { toolKeys } from "~api/toolKeys.ts";
import { updateTool } from "~components/Resources/ToolAdministration/api/toolAdministration.ts";
import { timeEntriesKeys } from "~components/TimeManagement/api/timeEntries.ts";
import { timeTrackingKeys } from "~components/TimeManagement/api/timeTracking.ts";
import {
  createTimeTrackingEvents,
  createTimeTrackingEventsMutationKey,
} from "~components/TimeManagement/api/timeTrackingEvent.ts";
import { ApiError } from "~generated";

import { handleError } from "../lib/handleError.ts";

import { config } from "./config.ts";

import type { PersistRetryer } from "@tanstack/react-query-persist-client";

function useInitQueryClient() {
  return useMemo(() => {
    const queryClient = new QueryClient({
      // see https://aronschueler.de/blog/2022/12/16/generating-meaningful-issues-in-sentry-with-react-query-+-axios/
      // for error handling
      queryCache: new QueryCache({
        onError: (err, query) => {
          withScope((scope) => {
            scope.setContext("query", { queryHash: query.queryHash });
            captureException(err);
          });
        },
      }),
      mutationCache: new MutationCache({
        onError: (err, _variables, _context, mutation) => {
          if (err instanceof ApiError && err.status === 422) {
            // 422 is a validation error, so we don't need to capture it
            return;
          }

          withScope((scope) => {
            scope.setContext("mutation", {
              mutationId: mutation.mutationId,
              variables: mutation.state.variables,
            });
            captureException(err);
          });
        },
      }),
      defaultOptions: {
        queries: {
          networkMode: "online",
          refetchOnWindowFocus: false,
          gcTime: hoursToMilliseconds(24),
        },
        mutations: {
          networkMode: "online",
          gcTime: hoursToMilliseconds(24) * 7,
        },
      },
    });

    queryClient.setMutationDefaults(toolKeys.updateToolMutationKey, {
      mutationFn: updateTool,
      onSettled: async () => {
        await queryClient.invalidateQueries({ queryKey: toolKeys.base() });
      },
    });
    queryClient.setMutationDefaults(createTimeTrackingEventsMutationKey, {
      mutationFn: createTimeTrackingEvents,
      onSettled: async (_data, _error, { tenantId }) => {
        await queryClient.invalidateQueries({
          queryKey: timeTrackingKeys.base(tenantId),
        });
        await queryClient.invalidateQueries({
          queryKey: timeEntriesKeys.base(tenantId),
        });
      },
    });

    if (!onlineManager.isOnline()) {
      // when offline while opening the page, queryClient.resumePausedMutations()
      // does not work reliably, so we wait to be online and then resume all paused
      // mutations once.
      const unsubscribe = onlineManager.subscribe(async () => {
        if (onlineManager.isOnline()) {
          const mutations = queryClient
            .getMutationCache()
            .getAll()
            .filter((m) => m.state.isPaused);

          await Promise.all(mutations.map((m) => m.continue().catch(() => {})));
          await queryClient.invalidateQueries();
          unsubscribe();
        }
      });
    }

    return queryClient;
  }, []);
}

const asyncStorage = {
  getItem: (key: string) => get(key),
  setItem: (key: string, value: string) => set(key, value),
  removeItem: (key: string) => del(key),
};

const persister = createAsyncStoragePersister({
  storage: asyncStorage,
  retry: (props: Parameters<PersistRetryer>[0]) => {
    handleError(props.error);
    return removeOldestQuery(props);
  },
});

export function InitializeReactQuery({ children }: PropsWithChildren) {
  const queryClient = useInitQueryClient();

  if (config().disablePersistQueryCache === "true") {
    return (
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    );
  }

  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister }}
      // onSuccess is called twice due to react-strict mode
      // so we end up with duplicate mutations in development.
      onSuccess={async () => {
        await queryClient.resumePausedMutations();
        await queryClient.invalidateQueries();
      }}
    >
      {children}
    </PersistQueryClientProvider>
  );
}
