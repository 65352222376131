import { findEntity } from "~api/helpers.ts";

import type { ConstructionSite, Employee, WorkPackage } from "~generated";
import type { Resource } from "~lib/resourceHelpers.ts";

type Id = string | null | undefined;

export class DataProvider {
  constructor(
    private constructionSites: ConstructionSite[],
    private resources: Resource[],
    private workPackages: WorkPackage[],
    private employees: Employee[],
  ) {}

  findConstructionSite(id: Id) {
    return findEntity(this.constructionSites, id);
  }

  findResource(id: Id) {
    return findEntity(this.resources, id);
  }

  findWorkPackage(id: Id) {
    return findEntity(this.workPackages, id);
  }

  findEmployee(id: Id) {
    return findEntity(this.employees, id);
  }
}
