import { addDays } from "date-fns/addDays";
import { differenceInDays } from "date-fns/differenceInDays";
import { format } from "date-fns/format";
import { getWeekOfMonth } from "date-fns/getWeekOfMonth";
import { getWeeksInMonth } from "date-fns/getWeeksInMonth";
import { intervalToDuration } from "date-fns/intervalToDuration";
import { isValid } from "date-fns/isValid";
import { parse } from "date-fns/parse";
import { parseISO } from "date-fns/parseISO";

import type { DateRange as MuiDateRange } from "@mui/x-date-pickers-pro";
import type { Locale } from "date-fns/types";

export const MIN_DATE = new Date(1000, 0, 1);
export const MAX_DATE = new Date(3000, 0, 1);

/**
 * Parse a date with a given locale
 * @param {string} str
 * @param {object} locale a locale from date-fns/locale (can be obtained via useLocale())
 * @returns
 */
export function parseDate(str: string, locale: Locale) {
  const date = parse(
    str,
    locale.formatLong?.date({ width: "short" }),
    new Date(),
  );
  return Number.isFinite(date.getTime()) ? date : null;
}

export function isValidDate(date: unknown): date is Date {
  return date instanceof Date && isValid(date);
}

export function convertToDateOrNull(dateString: string | undefined | null) {
  return dateString ? parseISO(dateString) : null;
}

export function validDateOrDefault(
  date: Date | null | undefined,
  fallback: Date = new Date(),
) {
  return date && isValid(date) ? date : fallback;
}

export function formatDateOrNull(
  value: Date | null | undefined,
  formatString: string,
  options?: Parameters<typeof format>[2],
) {
  if (!value || !isValid(value)) {
    return null;
  }
  return format(value, formatString, options);
}

export type DateRange = { start: Date; end: Date };

export function formatDateRangeOrNull({
  dateRange,
  formatString = "P",
  options,
}: {
  dateRange: DateRange | null | undefined;
  formatString?: string;
  options?: Parameters<typeof format>[2];
}) {
  const from = formatDateOrNull(dateRange?.start, formatString, options);
  const to = formatDateOrNull(dateRange?.end, formatString, options);
  return from && to ? `${from} - ${to}` : null;
}

export function muiDateRangeToDateRange(
  dateRange: MuiDateRange<Date> | null | undefined,
): DateRange | null {
  if (!dateRange || !dateRange[0] || !dateRange[1]) {
    return null;
  }
  return { start: dateRange[0], end: dateRange[1] };
}

export function dateRangeToMuiDateRange(
  dateRange: DateRange,
): MuiDateRange<Date> {
  return [dateRange.start, dateRange.end];
}

export function isLastMonthWeek(date: Date) {
  return (
    getWeekOfMonth(date, { weekStartsOn: 1 }) ===
    getWeeksInMonth(date, { weekStartsOn: 1 })
  );
}

export function moveDateRange({
  dateRange,
  newStartDate,
}: {
  dateRange: DateRange;
  newStartDate: Date;
}): DateRange {
  const days = differenceInDays(dateRange.end, dateRange.start);

  return { start: newStartDate, end: addDays(newStartDate, days) };
}

export function combineDateWithTime({
  date,
  time,
}: {
  date: Date;
  time: Date;
}) {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    time.getHours(),
    time.getMinutes(),
    time.getSeconds(),
    time.getMilliseconds(),
  );
}

export function createTime({
  hours,
  minutes,
  seconds,
}: {
  hours?: number | undefined;
  minutes?: number | undefined;
  seconds?: number | undefined;
}): Date {
  // The year, month and day are arbitrary
  return new Date(2024, 1, 1, hours || 0, minutes || 0, seconds || 0);
}

export function createDateFromSeconds(seconds?: number | null): Date | null {
  if (seconds === undefined || seconds === null) {
    return null;
  }
  const duration = intervalToDuration({
    start: 0,
    end: seconds * 1000,
  });
  return createTime(duration);
}

export function secondsFromDate(date: Date): number {
  return date.getHours() * 3600 + date.getMinutes() * 60 + date.getSeconds();
}
